/* Linci Styles System v1.0 */

import { darken, lighten, rgba } from "polished";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { createGlobalStyle, css } from "styled-components";
import { ReactComponent as CoinIconATOM } from "../images/CoinIconATOM.svg";
import { ReactComponent as CoinIconDOT } from "../images/CoinIconDOT.svg";
import { ReactComponent as CoinIconEOS } from "../images/CoinIconEOS.svg";
import { ReactComponent as CoinIconETH } from "../images/CoinIconETH.svg";
import { ReactComponent as CoinIconIOST } from "../images/CoinIconIOST.svg";
import { ReactComponent as CoinIconKSM } from "../images/CoinIconKSM.svg";
import { ReactComponent as CoinIconLPT } from "../images/CoinIconLPT.svg";
import { ReactComponent as CoinIconPCX } from "../images/CoinIconPCX.svg";
import { ReactComponent as CoinIconUSER } from '../images/CoinIconUSER.svg';
import { ReactComponent as CoinIconVETH } from '../images/CoinIconVETH.svg';
import { ReactComponent as CoinIconVOST } from "../images/CoinIconVOST.svg";
import { ReactComponent as IconHome } from "../images/IconHome.svg";
import { ReactComponent as IconSearch } from "../images/IconSearch.svg";
import Formatter from "../utils/Formatter";


const breakpoint = 800;

export const mobile = (...args) => css`
  @media (max-width: ${breakpoint}px) {
    ${css(...args)}
  }
`;

export const desktop = (...args) => css`
  @media (min-width: ${breakpoint + 1}px) {
    ${css(...args)}
  }
`;

export const color = {
  transparent: "transparent",
  black: "hsl(243, 10%, 10%)",
  white: "white",
  darkGray: "hsl(243, 10%, 25%)",
  gray: "hsl(243, 5%, 50%)",
  lightGray: "hsl(243, 5%, 75%)",
  lightGrayBackground: "#f5f5f5",
  ksmFontGray: '#BCBCC1',
  washedGray: "#f8f9fa",
  washedRed: "#ffdfdf",
  red: "rgb(255,59,48)",
  orange: "rgb(255,149,0)",
  washedYellow: "#fffceb",
  yellow: "rgb(255,204,0)",
  washedGreen: "#e8fdf5",
  green: "#65f38c",
  darkGreen: "#009090",
  tealGreen: "hsl(182, 70%, 50%)",
  washedBlue: "#f6fffe",
  blue: "rgb(0,122,255)",
  darkBlue: "#1d3549",
  tealBlue: "rgb(90,200,250)",
  purple: "rgb(88,86,214)",
  pink: "rgb(255,45,85)",

  stake: "hsl(171, 65%, 45%)",
  pool: "hsl(243, 85%, 65%)",
  news: "hsl(347, 75%, 55%)",

  wechat: "#04c160",
  telegram: "#2fa6e1",
  iost: "#000000",
  eos: "#363754",
  eth: "#473654",
  atom: "#363D54",
  lpt: "#364754",
  ksm: "#3f3f3f",
  lol: "#364754",
  pcx: "#7b6116",
  dot: "#543642",
  user: '#323232',

  border: {
    sm: rgba("#202020", 0.075),
    md: rgba("#202020", 0.1),
    lg: rgba("#202020", 0.2),
    xl: rgba("#202020", 0.4)
  }
};

export const shadow = {
  sm: "0 0.0625rem 0.25rem 0 " + rgba(color.black, 0.1),
  md: "0 0.125rem 0.5rem 0 " + rgba(color.black, 0.09),
  lg: "0 0.25rem 1rem 0 " + rgba(color.black, 0.08),
  xl: "0 1rem 4rem 0 " + rgba(color.black, 0.07)
};

export const radius = {
  sm: "0.25em",
  md: "0.5em",
  lg: "1em",
  xl: "1.5em",
  rounded: "999px"
};

const controllerPrimaryColor = color.tealBlue;
const controllerSecondaryColor = color.black;
const controllerDisabledColor = rgba(color.lightGray, 0.5);

export const Button = styled.button`
  cursor: ${p => (p.disabled ? "not-allowed" : "pointer")};
  width: ${p => p.fullWidth && "100%"};
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 150ms, background-color 150ms;
  font-weight: bold;

  ${mobile`
    padding: 0.75em 1.125em;
    padding-left: ${p => p.withIcon && "0.9375em"};
    svg {
      margin-right: 0.35em;
    }
  `};

  ${desktop`
    padding: 0.5em 0.75em;
    padding-left: ${p => p.withIcon && "0.625em"};
    svg {
      margin-right: 0.35em;
    }
  `};

  color: ${p =>
    p.ghost
      ? p.disabled
        ? controllerDisabledColor
        : p.primary
          ? controllerPrimaryColor
          : lighten(0.15, controllerSecondaryColor)
      : "white"};

  background: ${p =>
    p.ghost
      ? "transparent"
      : p.disabled
        ? controllerDisabledColor
        : p.primary
          ? controllerPrimaryColor
          : controllerSecondaryColor};

  border-radius: ${p => (p.rounded ? radius.rounded : radius.md)};

  border: 1px solid
    ${p =>
    p.ghost
      ? p.disabled
        ? controllerDisabledColor
        : p.primary
          ? controllerPrimaryColor
          : rgba(controllerSecondaryColor, 0.15)
      : "transparent"};

  svg {
    display: block;
    height: 1em;
    width: auto;
    fill: ${p =>
    p.ghost
      ? p.disabled
        ? controllerDisabledColor
        : p.primary
          ? controllerPrimaryColor
          : color.black
      : "white"};
  }

  @media (hover: hover) {
    :hover {
      background: ${p =>
    p.ghost
      ? p.disabled
        ? "tranparent"
        : p.primary
          ? rgba(controllerPrimaryColor, 0.05)
          : rgba(controllerSecondaryColor, 0.025)
      : p.disabled
        ? null
        : p.primary
          ? lighten(0.05, controllerPrimaryColor)
          : lighten(0.2, controllerSecondaryColor)};
    }
  }

  :focus {
    box-shadow: ${p =>
    p.ghost
      ? "0 0 0 0.15em " +
      (p.primary
        ? rgba(controllerPrimaryColor, 0.3)
        : rgba(controllerSecondaryColor, 0.1))
      : "0 0 0 0.075em white, 0 0 0 0.15em " +
      (p.primary ? controllerPrimaryColor : controllerSecondaryColor)};
  }

  :active {
    background: ${p =>
    p.ghost
      ? p.disabled
        ? "transparent"
        : p.primary
          ? rgba(controllerPrimaryColor, 0.1)
          : rgba(controllerSecondaryColor, 0.05)
      : p.disabled
        ? null
        : p.primary
          ? darken(0.05, controllerPrimaryColor)
          : darken(0.2, controllerSecondaryColor)};
  }
`;

export const TextInput = styled.input`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.md};
  padding: 0.5em;
  border: ${p =>
    p.error
      ? "1px solid " + color.red
      : "1px solid " + rgba(controllerSecondaryColor, 0.1)};
  background: transparent;
  color: ${p => (p.error ? color.red : color.black)};
  width: ${p => (p.width ? p.width + "rem" : "100%")};
  max-width: ${p => (p.maxWidth ? p.maxWidth + "rem" : "auto")};
  ::placeholder {
    color: ${color.lightGray};
  }

  ${p =>
    !p.error &&
    css`
      @media (hover: hover) {
        :hover {
          border: 1px solid ${rgba(controllerSecondaryColor, 0.5)};
        }
      }
    `};

  :focus {
    border: 1px solid
      ${p => (p.error ? color.red : rgba(controllerSecondaryColor, 0.5))};
    box-shadow: 0 0 0 0.15em
      ${p =>
    p.error ? rgba(color.red, 0.2) : rgba(controllerSecondaryColor, 0.1)};
  }
`;

export const View = styled.div`
  z-index: ${p => p.z};
  display: ${p => p.inline && "inline-block"};
  flex: ${p => p.flex};
  text-align: ${p =>
    p.center ? "center" : p.left ? "left" : p.right && "right"};

  max-width: ${p => p.maxWidth && p.maxWidth + "em"};
  min-width: ${p => p.minWidth && p.minWidth + "em"};
  min-height: ${p => p.minHeight && p.minHeight + "em"};

  background: ${p => p.bg};
  border-radius: ${p => p.r};
  box-shadow: ${p => p.s};

  border: ${p => p.b && "1px solid " + p.b};
  border-top: ${p =>
    p.by ? "1px solid " + p.by : p.bt && "1px solid " + p.bt};
  border-right: ${p =>
    p.bx ? "1px solid " + p.bx : p.br && "1px solid " + p.br};
  border-bottom: ${p =>
    p.by ? "1px solid " + p.by : p.bb && "1px solid " + p.bb};
  border-left: ${p =>
    p.bx ? "1px solid " + p.bx : p.bl && "1px solid " + p.bl};

  ${mobile`
    width: ${p =>
      p.w && (typeof p.w === "number" ? p.w + "em" : p.w[0] && p.w[0] + "em")};
    height:${p =>
      p.h && (typeof p.h === "number" ? p.h + "em" : p.h[0] && p.h[0] + "em")};
    margin: ${p =>
      p.m && (typeof p.m === "number" ? p.m + "em" : p.m[0] && p.m[0] + "em")};
    margin-top: ${p =>
      p.my
        ? typeof p.my === "number"
          ? p.my + "em"
          : p.my[0] && p.my[0] + "em"
        : p.mt &&
        (typeof p.mt === "number" ? p.mt + "em" : p.mt[0] && p.mt[0] + "em")};
    max-height:${p =>
      p.maxHeight && (typeof p.maxHeight === "number" ? p.maxHeight + "em" : p.maxHeight[0] && p.maxHeight[0] + "em")};
    margin-right: ${p =>
      p.mx
        ? typeof p.mx === "number"
          ? p.mx + "em"
          : p.mx[0] && p.mx[0] + "em"
        : p.mr &&
        (typeof p.mr === "number" ? p.mr + "em" : p.mr[0] && p.mr[0] + "em")};
    margin-bottom: ${p =>
      p.my
        ? typeof p.my === "number"
          ? p.my + "em"
          : p.my[0] && p.my[0] + "em"
        : p.mb &&
        (typeof p.mb === "number" ? p.mb + "em" : p.mb[0] && p.mb[0] + "em")};
    margin-left: ${p =>
      p.mx
        ? typeof p.mx === "number"
          ? p.mx + "em"
          : p.mx[0] && p.mx[0] + "em"
        : p.ml &&
        (typeof p.ml === "number" ? p.ml + "em" : p.ml[0] && p.ml[0] + "em")};

    padding: ${p =>
      p.p && (typeof p.p === "number" ? p.p + "em" : p.p[0] && p.p[0] + "em")};
    padding-top: ${p =>
      p.py
        ? typeof p.py === "number"
          ? p.py + "em"
          : p.py[0] && p.py[0] + "em"
        : p.pt &&
        (typeof p.pt === "number" ? p.pt + "em" : p.pt[0] && p.pt[0] + "em")};
    max-height:${p =>
      p.maxHeight && (typeof p.maxHeight === "number" ? p.maxHeight + "em" : p.maxHeight[1] && p.maxHeight[1] + "em")};
    padding-right: ${p =>
      p.px
        ? typeof p.px === "number"
          ? p.px + "em"
          : p.px[0] && p.px[0] + "em"
        : p.pr &&
        (typeof p.pr === "number" ? p.pr + "em" : p.pr[0] && p.pr[0] + "em")};
    padding-bottom: ${p =>
      p.py
        ? typeof p.py === "number"
          ? p.py + "em"
          : p.py[0] && p.py[0] + "em"
        : p.pb &&
        (typeof p.pb === "number" ? p.pb + "em" : p.pb[0] && p.pb[0] + "em")};
    padding-left: ${p =>
      p.px
        ? typeof p.px === "number"
          ? p.px + "em"
          : p.px[0] && p.px[0] + "em"
        : p.pl &&
        (typeof p.pl === "number" ? p.pl + "em" : p.pl[0] && p.pl[0] + "em")};
  `}

  ${desktop`
    width: ${p =>
      p.w && (typeof p.w === "number" ? p.w + "em" : p.w[1] && p.w[1] + "em")};
    height:${p =>
      p.h && (typeof p.h === "number" ? p.h + "em" : p.h[1] && p.h[1] + "em")};
    margin: ${p =>
      p.m && (typeof p.m === "number" ? p.m + "em" : p.m[1] && p.m[1] + "em")};
    margin-top: ${p =>
      p.my
        ? typeof p.my === "number"
          ? p.my + "em"
          : p.my[1] && p.my[1] + "em"
        : p.mt &&
        (typeof p.mt === "number" ? p.mt + "em" : p.mt[1] && p.mt[1] + "em")};
    margin-right: ${p =>
      p.mx
        ? typeof p.mx === "number"
          ? p.mx + "em"
          : p.mx[1] && p.mx[1] + "em"
        : p.mr &&
        (typeof p.mr === "number" ? p.mr + "em" : p.mr[1] && p.mr[1] + "em")};
    margin-bottom: ${p =>
      p.my
        ? typeof p.my === "number"
          ? p.my + "em"
          : p.my[1] && p.my[1] + "em"
        : p.mb &&
        (typeof p.mb === "number" ? p.mb + "em" : p.mb[1] && p.mb[1] + "em")};
    margin-left: ${p =>
      p.mx
        ? typeof p.mx === "number"
          ? p.mx + "em"
          : p.mx[1] && p.mx[1] + "em"
        : p.ml &&
        (typeof p.ml === "number" ? p.ml + "em" : p.ml[1] && p.ml[1] + "em")};

    padding: ${p =>
      p.p && (typeof p.p === "number" ? p.p + "em" : p.p[1] && p.p[1] + "em")};
    padding-top: ${p =>
      p.py
        ? typeof p.py === "number"
          ? p.py + "em"
          : p.py[1] && p.py[1] + "em"
        : p.pt &&
        (typeof p.pt === "number" ? p.pt + "em" : p.pt[1] && p.pt[1] + "em")};
    padding-right: ${p =>
      p.px
        ? typeof p.px === "number"
          ? p.px + "em"
          : p.px[1] && p.px[1] + "em"
        : p.pr &&
        (typeof p.pr === "number" ? p.pr + "em" : p.pr[1] && p.pr[1] + "em")};
    padding-bottom: ${p =>
      p.py
        ? typeof p.py === "number"
          ? p.py + "em"
          : p.py[1] && p.py[1] + "em"
        : p.pb &&
        (typeof p.pb === "number" ? p.pb + "em" : p.pb[1] && p.pb[1] + "em")};
    padding-left: ${p =>
      p.px
        ? typeof p.px === "number"
          ? p.px + "em"
          : p.px[1] && p.px[1] + "em"
        : p.pl &&
        (typeof p.pl === "number" ? p.pl + "em" : p.pl[1] && p.pl[1] + "em")};
  `}
`;

export const Text = styled(View)`
  ${mobile`
    font-size: ${p =>
      (p.scale || p.scale === 0) &&
      (typeof p.scale === "number"
        ? Math.pow(1.1, p.scale) + "rem"
        : (p.scale[0] || p.scale[0] === 0) &&
        Math.pow(1.1, p.scale[0]) + "rem")};
  `}

  ${desktop`
    font-size: ${p =>
      (p.scale || p.scale === 0) &&
      (typeof p.scale === "number"
        ? Math.pow(1.15, p.scale) + "rem"
        : (p.scale[1] || p.scale[1] === 0) &&
        Math.pow(1.15, p.scale[1]) + "rem")};
  `}
  
  font-weight: ${p =>
    p.bold ? "bold" : p.lighter ? "lighter" : p.normal && "normal"};
  line-height: ${p => p.paragraph && "1.35"};
  color: ${p => p.color};
  text-transform: ${p =>
    p.uppercase ? "uppercase" : p.lowercase && "uppercase"};;
  word-break: ${p => p.break && " break-all"};
`;

export const ScrollView = styled(View)`
  ${mobile`
    overflow: auto;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar {
      display: none;
    }
  `}
  ${desktop`
    overflow: auto;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const Flex = styled(View)`
  display: ${p => (p.inline ? "inline-flex" : "flex")};
  flex-direction: ${p =>
    p.column
      ? p.reverse
        ? "column-reverse"
        : "column"
      : p.reverse
        ? "row-reverse"
        : "row"};
  align-items: ${p =>
    p.center || p.aic ? "center" : p.aifs ? "flex-start" : p.aife ? "flex-end" : "stretch"};

  justify-content: ${p =>
    p.center || p.jcc ? "center" : p.jcsb ? "space-between" : p.jcfe ? 'flex-end' : p.jcsa ? 'space-around' : 'flex-start'};
  flex-wrap: ${p => p.wrap && " wrap"};
  > * {
    flex: ${p => p.child};
  }
  > *:not(:last-child) {
    ${mobile`
      margin-bottom:${p =>
      p.gap &&
      (p.column || p.responsive) &&
      (typeof p.gap === "number"
        ? p.gap + "em"
        : p.gap[0] && p.gap[0] + "em")};
      margin-right:${p =>
      p.gap &&
      (!(p.column || p.responsive) &&
        (typeof p.gap === "number"
          ? p.gap + "em"
          : p.gap[0] && p.gap[0] + "em"))};
    `}

    ${desktop`
      margin-bottom:${p =>
      p.gap &&
      p.column &&
      (typeof p.gap === "number"
        ? p.gap + "em"
        : p.gap[1] && p.gap[1] + "em")};
      margin-right:${p =>
      p.gap &&
      (!p.column &&
        (typeof p.gap === "number"
          ? p.gap + "em"
          : p.gap[1] && p.gap[1] + "em"))};
    `}
  }
  ${p =>
    p.responsive &&
    mobile`
      flex-direction: column
  `};
`;

export const Break = styled(View)`
  flex-basis: 100%;
  height: 0;
`;

// 以下是本项目

export const Hidden = styled(View)`
  ${mobile`
    display: ${p => p.mobile && "none"};
  `}

  ${desktop`
    display: ${p => p.desktop && "none"};
  `}
`;

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica,
      Roboto, Arial, PingFang SC, Hiragino Sans GB, Microsoft Yahei,
      Microsoft Jhenghei, sans-serif;

    color: ${color.black};
    a {
      color: ${color.black};
    }
  }
`;

export const Tags = styled(Text)`
  display: flex;
  > * {
    :not(:first-child),
    :not(:last-child) {
      border-radius: 0;
    }
    :first-child {
      padding-left: ${p => p.rounded && "0.75em"};
      border-top-left-radius: ${p => (p.rounded ? radius.rounded : radius.md)};
      border-bottom-left-radius: ${p =>
    p.rounded ? radius.rounded : radius.md};
    }
    :last-child {
      padding-right: ${p => p.rounded && "0.75em"};
      border-top-right-radius: ${p => (p.rounded ? radius.rounded : radius.md)};
      border-bottom-right-radius: ${p =>
    p.rounded ? radius.rounded : radius.md};
    }
  }
`;

export const Tag = styled(Text).attrs({
  bold: true
})`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.6em;
  background: ${p => p.bg};
  border-radius: ${p => (p.rounded ? radius.rounded : radius.md)};
`;

export const SVG = props => (
  <StyledSVG {...props}>
    <props.svg />
  </StyledSVG>
);

const StyledSVG = styled(View).attrs({
  inline: true
})`
  margin-right: ${p => p.withText && "0.35em"};
  svg {
    display: block;
    fill: ${p => p.fill};
    height: ${p => (p.height ? p.height : 1) + "em"};
    width: auto;
    transform: ${p => (p.flipX ? "scaleX(-1)" : p.flipY && "scaleY(-1)")};
  }
`;

export const Page = props => (
  <View {...props} bg="#fafbfc" s={"inset " + shadow.md}>
    <StyledPage px={[1, 5]} py={[1, 2.5]}>
      {props.children}
    </StyledPage>
  </View>
);

export const StyledPage = styled(View)`
  max-width: 1680px;
  margin: 0 auto;
`;

export const Panel = styled(View).attrs({
  p: [1.75, 2]
})`
  max-width: 100%;
  background: ${p => (p.bg ? p.bg : "white")};
  border-radius: ${radius.lg};
  box-shadow: ${shadow.md};
`;

export const NoData = () => {
  const { t } = useTranslation();

  return (
    <View center bg={color.white} p={5}>
      <View inline p={1} bg={color.washedGray} r={radius.md}>
        <SVG svg={IconSearch} height={2} fill={color.lightGray} />
      </View>
      <Text scale={-1} mt={1} color={color.lightGray}>
        {t("暂无数据")}
      </Text>
    </View>
  );
};

export const Balance = ({ amount, units, scale }) => {
  const amountStr = Formatter.balanceNumberFormat(amount);
  const amountList = amountStr.split(".");
  const integer = amountList[0];
  const decimal = amountList.length > 1 ? amountList[1] : null;
  return (<>
    {scale ? (<Text as="span" scale={scale}>
      {integer}
      <Text as="span" color={color.gray} scale={scale}>
        {decimal && "." + decimal}
      </Text>
      {units && (
        <Text as="span" pl={0.25} color={color.lightGray} scale={scale} >
          {units}
        </Text>
      )}

    </Text>) : (<Text as="span">
      {integer}
      <Text as="span" color={color.gray}>
        {decimal && "." + decimal}
      </Text>
      {units && (
        <Text as="span" pl={0.25} color={color.lightGray}  >
          {units}
        </Text>
      )}

    </Text>)}

  </>);
};
export const KsmBalance = ({ amount, units, scale }) => {
  const amountStr = Formatter.balanceNumberFormat(amount);
  const amountList = amountStr.split(".");
  const integer = amountList[0];
  const decimal = amountList.length > 1 ? amountList[1] : null;
  return (<>
    {scale ? (<Text as="span" color='#BCBCC1' scale={scale}>
      {integer}
      <Text as="span" color='#BCBCC1' scale={scale}>
        {decimal && "." + decimal}
      </Text>
      {units && (
        <Text as="span" pl={0.25} color='#BCBCC1' scale={scale} >
          {units}
        </Text>
      )}

    </Text>) : (<Text as="span">
      {integer}
      <Text as="span" color='#BCBCC1'>
        {decimal && "." + decimal}
      </Text>
      {units && (
        <Text as="span" pl={0.25} color='#BCBCC1'  >
          {units}
        </Text>
      )}

    </Text>)}

  </>);
};

export const CoinIcon = props => (
  <Flex
    aic
    jcc
    r={radius.rounded}
    bg={
      props.abbr === "user"
        ? "linear-gradient(135deg, " +
        lighten(0, color.user) +
        " 0%, " +
        color.user +
        " 100%)"
        : props.abbr === "iost"
          ? "linear-gradient(135deg, " +
          lighten(0.25, color.iost) +
          " 0%, " +
          color.iost +
          " 100%)"
          : props.abbr === "eos"
            ? "linear-gradient(135deg, " +
            lighten(0.1, color.eos) +
            " 0%, " +
            color.eos +
            " 100%)"
            : props.abbr === "eth"
              ? "linear-gradient(135deg, " +
              lighten(0.1, color.eth) +
              " 0%, " +
              color.eth +
              " 100%)"
              : props.abbr === "atom"
                ? "linear-gradient(135deg, " +
                lighten(0.1, color.atom) +
                " 0%, " +
                color.atom +
                " 100%)"
                : props.abbr === "lpt"
                  ? "linear-gradient(135deg, " +
                  lighten(0.1, color.lpt) +
                  " 0%, " +
                  color.lpt +
                  " 100%)"
                  : props.abbr === "ksm"
                    ? "linear-gradient(135deg, " +
                    lighten(0.1, color.ksm) +
                    " 0%, " +
                    color.ksm +
                    " 100%)"
                    : props.abbr === "pcx"
                      ? "linear-gradient(135deg, " +
                      lighten(0.1, color.pcx) +
                      " 0%, " +
                      color.pcx +
                      " 100%)"
                      : props.abbr === "dot"
                        ? "linear-gradient(135deg, " +
                        lighten(0.1, color.dot) +
                        " 0%, " +
                        color.dot +
                        " 100%)"
                        : props.abbr === "vost" &&
                        "linear-gradient(135deg, " +
                        lighten(0.1, color.pool) +
                        " 0%, " +
                        color.pool +
                        " 100%)"
    }
  >
    <SVG
      svg={
        props.abbr === "iost"
          ? CoinIconIOST
          : props.abbr === "eos"
            ? CoinIconEOS
            : props.abbr === "eth"
              ? CoinIconETH
              : props.abbr === "veth"
                ? CoinIconVETH
                : props.abbr === "user"
                  ? CoinIconUSER
                  : props.abbr === "atom"
                    ? CoinIconATOM
                    : props.abbr === "lpt"
                      ? CoinIconLPT
                      : props.abbr === "dot"
                        ? CoinIconDOT
                        : props.abbr === "ksm"
                          ? CoinIconKSM
                          : props.abbr === "pcx"
                            ? CoinIconPCX
                            : props.abbr === "vost"
                              ? CoinIconVOST
                              : IconHome
      }
      height={3.5}
    />
  </Flex>
);

export const ListItem = props => (
  <View py={1} bt={color.border.sm}>
    <StyledListItem>
      <View as={Text} scale={[-1, 0]} flex="none" paragraph color={color.gray}>
        {props.label}
      </View>
      <Text break paragraph mt={[0.5, 0]} ml={[0, 2]}>
        {props.content}
      </Text>
    </StyledListItem>
    {props.description && (
      <Text scale={-2} color={color.gray} mt={0.5} right>
        {props.description}
      </Text>
    )}
  </View>
);

const StyledListItem = styled(Flex)`
  ${mobile`
    flex-direction:column;
  `}

  ${desktop`
    justify-content: space-between;
    > :not(:first-child) {
      text-align: right;
    }
  `}
`;
export const ExchangeInput = styled.div`
height:35px;
display: flex;
justify-content: space-between
align-content: center;
background: ${p => !p.focus && color.washedGray};
box-shadow: ${p =>
    p.focus &&
    "0 0 0 1px rgba(50,50,93,.1), 0 2px 8px 0 rgba(50,50,93,.05), 0 1px 1px 0 rgba(0,0,0,.05)"};
transition: all 300ms;
border-radius: 999px;
input,
input:hover,
input:active,
input:focus {
  ::placeholder {
    color: ${color.lightGray};
  }

  border: none;
  box-shadow: none;
  outline: none;
}
@media (hover: hover) {
  :hover {
    background: ${p => p => !p.focus && darken(0.015, color.washedGray)};
  }
}
`;
export const Scroll = styled.div`
position: relative;
display:flex;
justify-content:center;
margin-right:1em;
width:100%;
height:169px;
overflow-y:auto;
margin:10px auto;
`  
