import { DateTime } from "luxon";

export default class Formatter {
  static iostFormat(number) {
    // number = Number(Number(number).toFixed(8));
    let minimumFractionDigits = 0;
    let maximumFractionDigits = 4;
    return new Intl.NumberFormat("en", {
      minimumFractionDigits,
      maximumFractionDigits
    }).format(number);
  }

  static voteNumberFormat(number) {
    let minimumFractionDigits = 0;
    let maximumFractionDigits = 4;
    return new Intl.NumberFormat("en", {
      minimumFractionDigits,
      maximumFractionDigits
    }).format(number);
  }

  static vethNumberFormat(number) {
    if (number === 0 || !number) return 0
    let minimumFractionDigits = 0;
    let maximumFractionDigits = 4;
    return new Intl.NumberFormat("en", {
      minimumFractionDigits,
      maximumFractionDigits
    }).format(number);
  }

  static balanceNumberFormat(number) {
    let minimumFractionDigits = 0;
    let maximumFractionDigits = 8;
    return new Intl.NumberFormat("en", {
      minimumFractionDigits,
      maximumFractionDigits
    }).format(number);
  }

  static counterNumberFormat(number) {
    let minimumFractionDigits = 8;
    let maximumFractionDigits = 8;
    return new Intl.NumberFormat("en", {
      minimumFractionDigits,
      maximumFractionDigits
    }).format(number);
  }

  static yearYieldNumberFormat(number) {
    let minimumFractionDigits = 0;
    let maximumFractionDigits = 2;
    return new Intl.NumberFormat("en", {
      minimumFractionDigits,
      maximumFractionDigits
    }).format(number);
  }

  static txToUrl(txHash) {
    return `${process.env.REACT_APP_IOST_BROWSER}/tx/${txHash}`;
  }

  static dateFormat(timestamp) {
    const date = DateTime.fromISO(timestamp).toLocal();

    return DateTime.fromISO(timestamp).locale === "zh-CN"
      ? `${date.toLocaleString(DateTime.DATE_FULL)} ${date.toLocaleString(
        DateTime.TIME_24_WITH_SECONDS
      )}`
      : `${date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}`;
  }

  static toChineseNum(num) {
    if (num > 999 && num < 100000000000) {
      let n = num - num.toString().slice(3);
      var unit = "千百十亿千百十万千百十元角分",
        str = "";
      n += "00";
      var p = n.indexOf(".");
      if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
      unit = unit.substr(unit.length - n.length);
      for (var i = 0; i < n.length; i++)
        str += "零一二三四五六七八九".charAt(n.charAt(i)) + unit.charAt(i);
      return str
        .replace(/零(千|百|十|角)/g, "零")
        .replace(/(零)+/g, "零")
        .replace(/零(万|亿|元)/g, "$1")
        .replace(/(亿)万|一(十)/g, "$1$2")
        .replace(/^元零?|零分/g, "")
        .replace(/元$/g, "");
    } else return null;
  }
}
