import { ApolloClient, gql, InMemoryCache } from '@apollo/client';

const typeDefs = gql`
    extend type authenticationInfo {
        address: String
        timestamp: Int
        signature: String
    }
`;

export const defaultClient = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV === "development"
});

export const vethClient = new ApolloClient({
    uri: process.env.REACT_APP_VETH_GRAPHQL_URL,
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV === "development",
    typeDefs
});
