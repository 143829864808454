import { rgba } from "polished";
import React from "react";
import { useTranslation } from "react-i18next";
import { useScrollPosition } from "../components/Hooks";
import { ReactComponent as IconGlobe } from "../images/IconGlobe.svg";
import { ReactComponent as IconUser } from "../images/IconUser.svg";
import Mheader from "./Mheader";
import Navigator from "./Navigator";
import { color, Flex, Hidden, radius, shadow, SVG, Text } from "./Styles";


export default ({ isLandingPage, screen, pathname, setPathname, ...props }) => {
  const { t, i18n } = useTranslation();

  const scrollPosition = useScrollPosition();

  function renderSelectCoin() {
    switch (pathname) {
      case '/eth':
        return 'ETH';
      case '/iost':
        return "IOST"
      case '/ksm"':
        return 'KSM'
      default:
        return ''
    }
  }
  return (
    <Flex
      aic
      jcsb
      p={1.25}
      bg={rgba("white", 0.975)}
      z={10}
      s={
        screen === "mobile"
          ? scrollPosition > 90
            ? shadow.md
            : null
          : scrollPosition > 241
            ? shadow.md
            : null
      }
      style={{
        position: "sticky",
        top: 0,
        transition: isLandingPage ? "box-shadow 300ms ease-in-out" : null,
        transitionDelay: isLandingPage ? "150ms" : null
      }}
    >
      <Flex aic gap={1}>

        <Navigator screen={screen} />

        {!isLandingPage && (
          <Hidden desktop>
            <Flex> <Text
              as="span"
              px={0.75}
              py={0.5}
              bold
              bg={color.washedGray}
              r={radius.rounded}
              mr={1}
            >
              {renderSelectCoin()}

            </Text>
              <Mheader screen={screen} pathname={pathname} setPathname={setPathname} />
            </Flex>
          </Hidden>
        )}

        {!isLandingPage && (
          <Hidden mobile>
            <Text color={color.gray}>
              {t("去中心化 PoS 矿池，持有 vToken 即挖矿")}
            </Text>
          </Hidden>
        )}
      </Flex>

      <Flex gap={2}>
        {/*<Hidden mobile>*/}
          {/*<Text color={color.lightGray} right>*/}
            {/*<Flex aic>*/}
              {/*<SVG svg={IconUser} withText fill={color.lightGray} />*/}
              {/*{t("猎币账号即将上线")}*/}
            {/*</Flex>*/}
          {/*</Text>*/}
        {/*</Hidden>*/}

        <Flex
          onClick={() => {
            i18n.changeLanguage(i18n.language === "zh" ? "en" : "zh");
            localStorage.setItem("userLangStorage", i18n.language);
          }}
          style={{ cursor: "pointer" }}
          aic
        >
          <SVG svg={IconGlobe} withText fill={color.gray} />
          <Text color={color.gray}>
            {i18n.language === "zh" ? "EN" : "中文"}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
