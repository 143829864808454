
import claimJson from './ClaimRewards.json';
import depositContractJson from './DepositContract.json';
import mintDropContractJson from './MintDrop.json';
import uniswapV2Json from './UniswapV2Router02.json';
import vethContractJson from './vETH.json';

export default function getContracts(networkId, provider) {
    const depositContract = new provider.eth.Contract(depositContractJson.abi, networkId === 1 ? process.env.REACT_APP_DEPOSIT_CONTRACT_ID : process.env.REACT_APP_DEPOSIT_CONTRACT_ID_TEST)
    const vethContract = new provider.eth.Contract(vethContractJson.abi, networkId === 1 ? process.env.REACT_APP_VETH_CONTRACT_ID : process.env.REACT_APP_VETH_CONTRACT_ID_TEST)
    const mintDropContract = new provider.eth.Contract(mintDropContractJson.abi, networkId === 1 ? process.env.REACT_APP_MINT_CONTRACT_ID : process.env.REACT_APP_MINT_CONTRACT_ID_TEST)
    const uniswapV2Contract = new provider.eth.Contract(uniswapV2Json.abi, networkId === 1 ? process.env.REACT_APP_UNISWAP_CONTRACT_ID : process.env.REACT_APP_UNISWAP_CONTRACT_ID_TEST)
    const claimContract = new provider.eth.Contract(claimJson.abi, networkId === 1 ? process.env.REACT_APP_CLAIM_CONTRACT_ID : process.env.REACT_APP_CLAIM_CONTRACT_ID_TEST)

    return {
        depositContract,
        vethContract,
        mintDropContract,
        uniswapV2Contract,
        claimContract
    }
}