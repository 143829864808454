import { ApolloProvider, gql, useQuery } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { ApiPromise, WsProvider } from '@polkadot/api';
import { encodeAddress, setSS58Format } from '@polkadot/util-crypto';
import * as Sentry from "@sentry/browser";
import IOST from "iost";
import React, { Suspense, lazy, useEffect, useState } from "react";
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import {
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import "toasted-notes/src/styles.css";
import { defaultClient, vethClient } from './clients';
import Header from "./components/Header";
import { GlobalStyle, Hidden, Text, color } from "./components/Styles";
import "./i18n/i18n";
import { EthWeb3ContextProvider } from './lib/';
import Format from './utils/Format';
import Formatter from "./utils/Formatter";
import IostHandler from "./utils/IostHandler";

const Hero = lazy(() => import("./components/HeroETH"));
const Coins = lazy(() => import("./components/Coins"));
const Landing = lazy(() => import("./pages/EthLanding"));
const VOST = lazy(() => import("./pages/VOST"));
const ETH = lazy(() => import("./pages/ETH"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));
const KSM = lazy(() => import('./pages/KSM'))



function Main() {
  const [pathname, setPathname] = useState()
  const [iostHandler, setIostHandler] = useState();
  const [account, setAccount] = useState();
  // const [walletTipsST, setWalletTipsST] = useState(false);
  // const [tipsST, setTipsST] = useState(false);
  const [demoMode, setDemoMode] = useState(false);
  const [isLandingPage, setIsLandingPage] = useState(false);
  const [screen, setScreen] = useState("");
  const [currentCoin, setCurrentCoin] = useState(null);
  const [avgYearYield, setAvgYearYield] = useState("0");
  const [compondYearYield, setCompondYearYield] = useState("0");
  const [ethCompondYearYield, setEthCompondYearYield] = useState("0");
  const [ethTotalProfit, setEthTotalProfit] = useState(0)
  const [totalVotes, setTotalVotes] = useState('0')
  const [whoList, setWhoList] = useState('')
  const [own, setOwn] = useState('0')
  const [currentEra, setCurrentEra] = useState('')
  const [ksmValDayRewards, setKsmValDayRewards] = useState('0')
  const [ethTotal, setEthTotal] = useState(0);

  // ksm年化率
  const [compondKsmYearYield, setCompondKsmYearYield] = useState('0')
  // ksm总结算矿利
  const [ksmSettlementInterest, setKsmSettlementInterest] = useState('0')

  const [api, setApi] = useState(null)
  const { t, i18n } = useTranslation();
  const quarterlyAnnual = 0.128831857667509; // (Q3 总结算 + Q3 季度奖)/Q3 总票秒 * 86400 * 365
  const quarterlyExpect = 0.005466849007496; // Q3 季度奖/Q3 总票秒 * 86400 * 91 (2020-07-01 ～ 2020-09-30)
  useEffect(() => {
    if (api === null) {
      FindCurrentEra()
    }
  }, [api])
  useEffect(() => {
    if (currentEra !== '' && api !== null) {
      FindErasStakers()
    }
  }, [currentEra, api, FindErasStakers])
  async function FindCurrentEra() {
    const wsProvider = new WsProvider('wss://kusama.maiziqianbao.net/ws');
    const ksmapi = await ApiPromise.create({ provider: wsProvider });
    setApi(ksmapi)
    try {
      await ksmapi.query.staking.currentEra((res) => {
        console.log('返回currentEra', res.toJSON())
        setCurrentEra(res.toJSON())
      })
    }
    catch (error) { console.log('currentEra错误', error) }
  }
  async function FindErasStakers() {
    const ADDR = 'GLiebiQp5f6G5vNcc7BgRE9T3hrZSYDwP6evERn3hEczdaM'
    const res = await api.query.staking.erasStakers(currentEra, ADDR)
    if (`${res.toJSON().total}`.substr(0, 2) === "0x") {
      setTotalVotes(Format.except(parseInt(res.toJSON().total, 16)))
    }
    else {
      setTotalVotes(Format.except(res.toJSON().total))
    }
    setOwn(Format.except(res.toJSON().own))
    let List = res.toJSON().others.map((u) => {
      setSS58Format(2)
      let ksmaccount = encodeAddress(u.who);
      if (`${u.value}`.substr(0, 2) === "0x") {
        return { 'who': ksmaccount, 'value': Format.except(parseInt(u.value, 16)) }
      }
      else {
        return { 'who': ksmaccount, 'value': Format.except(u.value, 16) }
      }
    })
    console.log('返回currentEra 列表 ', List)
    setWhoList(List)
  }
  let tryTimes = 25;
  useEffect(() => {
    if (document.documentElement.clientWidth < 720) {
      setScreen("mobile");
    } else if (
      document.documentElement.clientWidth > 720 &&
      document.documentElement.clientWidth < 1440
    ) {
      setScreen("laptop");
    }

    let timer;
    function init() {
      tryTimes--;
      if (tryTimes > 0) {
        timer = setTimeout(() => {
          if (window.IWalletJS) {
            console.log(window.IWalletJS)
            walletLogin();
          } else {
            init();
          }
        }, 100);
      } else {
        walletLogin();
      }
    }

    init();
    return () => {
      clearTimeout(timer);
    };
  }, [tryTimes]);

  useEffect(() => {
    setPathname(document.location.pathname)
  }, []);

  function walletLogin() {
    let iostHandler;
    if (process.env.NODE_ENV === "development") {
      // 开发模式
      const accountObject = {
        name: process.env.REACT_APP_IOST_ACCOUNT_NAME,
        activeKey: process.env.REACT_APP_IOST_ACCOUNT_ACTIVE_KEY
      };
      iostHandler = new IostHandler(accountObject);
      setIostHandler(iostHandler);
      setDemoMode(false);
      setAccount(accountObject.name);
    } else if (window.IWalletJS) {
      window.IWalletJS.enable()
        .then(async account => {
          let iost = window.IWalletJS.newIOST(IOST);
          // 生产模式
          iostHandler = new IostHandler(null, iost);
          setIostHandler(iostHandler);
          setDemoMode(false);
          setAccount(account);

          ReactGA.set({
            userId: account,
          });

          ReactGA.event({
            category: "Unlock Wallet",
            action: "Unlock Wallet: " + account,
            value: account
          });
        })
        .catch(error => {
          console.log(error);
          if (error.type && error.type === "locked") {
            // setWalletTipsST(true);
          } else {
            Sentry.captureException(error);
          }
          setIostHandler(new IostHandler());
          setDemoMode(true);
          setAccount(process.env.REACT_APP_VOST_DEMO_ACCOUNT);
        });
    } else {
      // setTipsST(true);
      setIostHandler(new IostHandler());
      setDemoMode(true);
      setAccount(process.env.REACT_APP_VOST_DEMO_ACCOUNT);
    }
  }

  function renderMonitor() {

    return <Coins
      screen={screen}
      iostHandler={iostHandler}
      currentCoin={currentCoin}
      isLandingPage={isLandingPage}
      compondYearYield={compondYearYield}
      ethCompondYearYield={ethCompondYearYield}
      ethTotalProfit={ethTotalProfit}
      compondKsmYearYield={compondKsmYearYield}
    />

  }

  // eth 年化率
  const GET_VETH_ROI = gql`
     query getEthRoi {
        ROIRate {
          roiRate
         }
         totalDividendAmount {
            totalDividendAmount
         }
     }
     `
  useQuery(GET_VETH_ROI, {
    onCompleted: (data) => {
      // setEthCompondYearYield(isNaN(data.ROIRate.roiRate) ? '0.0' : parseFloat(data.ROIRate.roiRate * 100).toFixed(3).slice(0, -1).replace(/[.]?0+$/, ''))
      setEthTotalProfit(data.totalDividendAmount.totalDividendAmount)
    },
    client: vethClient
  });

  // useRequest(
  //   () =>
  //     getEthRoi()
  //   , {
  //     onSuccess: (data) => {
  //       setEthCompondYearYield(data?.vETH?.baseApy.toFixed(2))
  //     }
  //   })

  useEffect(() => {
    setEthCompondYearYield('24.03')
  }, [])




  return (
    <>
      {i18n.language !== 'zh' ? <Helmet>
        <title>Liebi Pool - Holder vToken i.e. Mining</title>
        <meta
          name="keywords"
          content="PoS, vToken, Liebi Pool, IOST, ETH, ETH 2.0, Staking"
        />
        <meta
          name="description"
          content="Liebi Pool is a PoS mining pool that can provide liquidity staking. Supports IOST, ETH now"
        />
      </Helmet> : <Helmet>
        <title>猎币矿池 - 持有矿权即挖矿</title>
        <meta
          name="keywords"
          content="猎币,矿池,PoS, vToken, Liebi Pool, IOST, ETH, ETH 2.0, Staking,矿权,Vote,挖矿,Mining,IOST,Cosmos,ATOM,Polkadot,ETH,Miner,矿利,投票,Ethereum "
        />
        <meta
          name="description"
          content="猎币矿池是由猎币团队开发的全新一代的PoS矿池，具备去中心化、高流动性、开放渠道等特性，首创矿权概念，持有猎币矿权，则可以立即享受多项去中心化矿池特有的福利。Liebi Pool is a PoS mining pool that can provide liquidity staking. Supports IOST, ETH now"
        />
      </Helmet>}
      <GlobalStyle />
      <Query query={GET_DIVNODE_Reward}>
        {({ loading, error, data }) => {
          if (loading) {
            console.log('正在查询')
            return null
          }
          if (error) {
            console.log('错误', error)
            return null
          }
          const yieldValue = parseInt(parseFloat(data.reward.valDayRewards) * 365 / parseFloat(totalVotes) * 10000) / 100
          // valDayRewards * 365 / 抵押矿池 * 100
          setKsmSettlementInterest(data.reward.totalReward)
          setKsmValDayRewards(data.reward.valDayRewards)
          if (isNaN(yieldValue) === true) {
          }
          else {
            setCompondKsmYearYield(yieldValue.toFixed(1))
          } return null
        }}

      </Query>
      <Query query={GET_DIVIDEND_YEAR_YIELD}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return null;

          const yieldValue = data.dividendYearYield.avgYearYield;
          setAvgYearYield(yieldValue);

          setCompondYearYield(
            Formatter.yearYieldNumberFormat(
              (Math.pow(1 + quarterlyAnnual / 365, 365) - 1) * 100
            )
          );
          return null;
        }}
      </Query>

      <Router>
        <Suspense fallback="">
          <Header isLandingPage={isLandingPage} screen={screen} setPathname={setPathname} pathname={pathname} />

          <Hero isLandingPage={isLandingPage} />

          {isLandingPage && (
            <Hidden desktop>
              <Text scale={-2} my={1.5} color={color.lightGray} center>
                {t("支持的链")}
              </Text>
            </Hidden>
          )}

          {renderMonitor()}

          <Route
            exact
            path="/"
            render={() => (
              <Landing
                setIsLandingPage={setIsLandingPage}
                compondYearYield={ethCompondYearYield}
                screen={screen}
                isLandingPage={isLandingPage}
              />
            )}
          />

          <Route
            path="/eth"
            render={() => {
              window.location.href = 'https://bifrost.app/vstaking/vETH';
              return null;
            }}
          />
          <Route
            path="/iost"
            render={() => (
              <VOST
                account={account}
                iostHandler={iostHandler}
                setCurrentCoin={setCurrentCoin}
                demoMode={demoMode}
                screen={screen}
                compondYearYield={compondYearYield}
                quarterlyExpect={quarterlyExpect}
              />
            )}
          />

          <Route
            path="/ksm"
            render={() => (
              <KSM
                // params={params}
                screen={screen}
                compondKsmYearYield={compondKsmYearYield}
                totalVotes={totalVotes}
                whoList={whoList}
                own={own}
                ksmSettlementInterest={ksmSettlementInterest}
                ksmValDayRewards={ksmValDayRewards}
                api={api}
                pathname={pathname}
                currentEra={currentEra}
              />
            )
            }
          />

          <Route
            path="/dot"
            render={() => <ComingSoon coin="Polkadot" themeColor={color.dot} />}
          />

          <Route
            path="/eos"
            render={() => <ComingSoon coin="EOS" themeColor={color.eos} />}
          />

          <Route
            path="/atom"
            render={() => <ComingSoon coin="Cosmos" themeColor={color.atom} />}
          />

          <Route
            path="/pcx"
            render={() => <ComingSoon coin="ChainX" themeColor={color.pcx} />}
          />

        </Suspense>
      </Router>

    </>
  );
};

export default function App() {
  return (
    <EthWeb3ContextProvider>
      <ApolloProvider client={defaultClient}>
        <Main />
      </ApolloProvider>
    </EthWeb3ContextProvider>

  );
}

const GET_DIVIDEND_YEAR_YIELD = gql`
  {
    dividendYearYield(days: 7) {
      avgYearYield
    }
  }
`;
const GET_DIVNODE_Reward = gql`
{
  reward {
      valDayRewards
      totalReward
  }
}

`;