import * as Sentry from "@sentry/browser";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';
import App from "./App";
import "./App.css";
import * as serviceWorker from "./serviceWorker";
import "./utils/Stats";

Sentry.init({
  dsn: "https://1eaba60829c842c5bb578c35d9f9ca6d@sentry.io/1454097"
});

ReactGA.initialize("UA-143690493-2");
ReactGA.pageview(window.location.pathname + window.location.search);

class Index extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Sentry.captureException(error);
    });
  }

  render() {
    return this.props.children;
  }
}

ReactDOM.render(
  <Index>
    <App />
  </Index>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
