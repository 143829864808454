import React, { useContext, useReducer } from 'react';

// Initial state for `useReducer`

const INIT_STATE = {
    ethWallet: null,
    ethWeb3Api: null,
    ethAccount: null,
    ethNetworkId: null,
    ethApiState: null,
    vethToEthRate: 1,
    ethMintDropTotal: 0,
    ethUserVethBalance: 0,
    ethUserEthBalance: 0,
    depositContract: null,
    vethContract: null,
    mintDropContract: null,
    claimContract: null,
    uniswapV2Contract: null,
    ethUserBindAddress: null,
    ethUserTrades: [],
    vethToEthTradeRate: {},
    ethToVethTradeRate: {},
};

///
// Reducer function for `useReducer`
const reducer = (state, action) => {
    switch (action.type) {
        case 'VETH_TO_ETH_TRADE_RATE':
            return { ...state, ...action.payload }
        case 'ETH_WALLET':
            return { ...state, ethWallet: action.payload }
        case 'ETH_CONNECT_INIT':
            return { ...state, ethApiState: null }
        case 'ETH_CONNECTING':
            return { ...state, ethApiState: 'CONNECTING' };
        case 'ETH_CONNECT_SUCCESS':
            return { ...state, ethWeb3Api: action.payload, ethApiState: 'READY' };
        case 'ETH_CONNECT_ERROR':
            return { ...state, ethApiState: 'ERROR' };
        case 'ETH_ACCOUNT':
            return { ...state, ethAccount: action.payload, ethApiState: 'READY' }
        case 'ETH_NETWORK_ID':
            return { ...state, ethNetworkId: action.payload }
        case 'ETH_MINT_DROP_TOTAL':
            return { ...state, ethMintDropTotal: action.payload }
        case 'ETH_USER_VETH_BALANCE':
            return { ...state, ethUserVethBalance: action.payload }
        case 'ETH_USER_ETH_BALANCE':
            return { ...state, ethUserEthBalance: action.payload }
        case 'ETH_CONTRACT':
            return { ...state, ...action.payload }
        case 'ETH_TRADES':
            return { ...state, ethUserTrades: action.payload }
        case 'ETH_TRADE':
            // trade => {hash, type: 'bindAddress', value, status: "pending | error"}
            const { ethUserTrades } = state;
            let trades = []
            if (!action.payload.hash) {
                return { ...state }
            }
            if (ethUserTrades.length > 0) {
                let existKey = 0
                ethUserTrades.map((trade, key) => {
                    console.log(key)
                    if (trade.hash === action.payload.hash) {
                        existKey = key + 1;
                    }
                })
                if (existKey) {
                    ethUserTrades[existKey - 1] = { ...ethUserTrades[existKey - 1], ...action.payload }
                    trades = ethUserTrades
                } else {
                    trades = [action.payload, ...ethUserTrades]
                }
            } else {
                trades = [...trades, action.payload]
            }
            window.localStorage.setItem('trades', JSON.stringify(trades))
            return { ...state, ethUserTrades: trades }
        default:
            throw new Error(`Unknown type: ${action.type}`);
    }
};

const EthWeb3Context = React.createContext();
const EthWeb3DispathContext = React.createContext();


const EthWeb3ContextProvider = (props) => {
    const initState = { ...INIT_STATE };
    const [state, dispatch] = useReducer(reducer, initState);
    return <EthWeb3Context.Provider value={state}>
        <EthWeb3DispathContext.Provider value={dispatch}>
            {props.children}
        </EthWeb3DispathContext.Provider>
    </EthWeb3Context.Provider>;
};


const useEthWeb3State = () => ({ ...useContext(EthWeb3Context) });
const useEthWeb3Dispatch = () => (useContext(EthWeb3DispathContext));

export { EthWeb3ContextProvider, useEthWeb3State, useEthWeb3Dispatch };
