import { Fetcher, Route, Token, TokenAmount, Trade, TradeType, WETH } from '@uniswap/sdk';
import Web3 from 'web3';
import getContracts from './contracts';
import vethContractJson from './contracts/vETH.json';
import { web3 } from './WalletProvider';
export async function connectEthWalletAuto(dispatch) {

    if (typeof window.ethereum === 'undefined') return; // MetaMask

    // set wallet to 'metamask'
    dispatch({ type: 'ETH_WALLET', payload: 'MetaMask' })

    const ethWeb3Api = new Web3(window.ethereum);
    dispatch({ type: 'ETH_CONNECT_SUCCESS', payload: ethWeb3Api })

    // Get Network id 
    const networkId = await ethWeb3Api.eth.net.getId();
    dispatch({ type: 'ETH_NETWORK_ID', payload: networkId })

    // Connecting to MetaMask
    const accounts = await ethWeb3Api.eth.requestAccounts()
    dispatch({ type: 'ETH_ACCOUNT', payload: accounts[0] })

    // When select wrong network will stop
    if (networkId != 1 && networkId != 4) {
        dispatch({ type: 'ETH_CONNECT_ERROR' });
        return;
    }
    if (networkId === 1 && (!process.env.REACT_APP_MINT_CONTRACT_ID || !process.env.REACT_APP_VETH_CONTRACT_ID)) {
        dispatch({ type: 'ETH_CONNECT_ERROR' });
        return;
    }

    // set Contract
    dispatch({ type: 'ETH_CONTRACT', payload: getContracts(networkId, ethWeb3Api) })


}

export async function connectEthWalletManual(dispatch) {
    if (typeof window.ethereum === 'undefined') return;

    dispatch({ type: 'ETH_CONNECTING' });

    const ethWeb3Api = new Web3(window.ethereum);

    // Connecting to MetaMask
    const accounts = await ethWeb3Api.eth.requestAccounts()
    console.log('--------accounts', accounts)
    dispatch({ type: 'ETH_ACCOUNT', payload: accounts[0] })


    // Get Network id 
    const networkId = await ethWeb3Api.eth.net.getId()
    dispatch({ type: 'ETH_NETWORK_ID', payload: networkId })

    const { vethContract } = getContracts(networkId, ethWeb3Api);

    // get total mint drop
    const totalMintDrop = await vethContract.methods.totalSupply().call()
    dispatch({ type: 'ETH_MINT_DROP_TOTAL', payload: ethWeb3Api.utils.fromWei(totalMintDrop) })
}

export async function changeAccount(dispatch, ethWeb3Api) {
    try {
        const accounts = await ethWeb3Api.eth.requestAccounts()
        dispatch({ type: 'ETH_ACCOUNT', payload: accounts[0] })
        connectEthWalletAuto(dispatch) // reload
    } catch (error) {
        console.log('当前无绑定账号')
        window.location.reload()
    }
}
export async function refreshBalance(dispatch, ethWeb3Api = web3) {

    if (!ethWeb3Api) return

    // Get Network id 
    const networkId = await ethWeb3Api.eth.net.getId();
    dispatch({ type: 'ETH_NETWORK_ID', payload: networkId })

    // set Contract
    dispatch({ type: 'ETH_CONTRACT', payload: getContracts(networkId, ethWeb3Api) })

    const vethContract = new ethWeb3Api.eth.Contract(vethContractJson.abi, networkId === 1 ? process.env.REACT_APP_VETH_CONTRACT_ID : process.env.REACT_APP_VETH_CONTRACT_ID_TEST)

    // get total mint drop
    const totalMintDrop = await vethContract.methods.totalSupply().call()
    dispatch({ type: 'ETH_MINT_DROP_TOTAL', payload: ethWeb3Api.utils.fromWei(totalMintDrop) })

    //  get accounts
    const accounts = await ethWeb3Api.eth.getAccounts();
    if (!(accounts && accounts[0])) {
        accounts[0] = process.env.REACT_APP_TEST_ACCOUNT_ADDRESS
    }

    dispatch({ type: 'ETH_ACCOUNT', payload: accounts[0] })

    // get my veth balance
    const vethBalance = await vethContract.methods.balanceOf(accounts[0]).call()
    dispatch({ type: 'ETH_USER_VETH_BALANCE', payload: ethWeb3Api.utils.fromWei(vethBalance) })

    // get my eth balance
    const ethBalance = await ethWeb3Api.eth.getBalance(accounts[0])
    dispatch({ type: 'ETH_USER_ETH_BALANCE', payload: ethWeb3Api.utils.fromWei(ethBalance) })

}
export async function closeConnect(dispatch) {
    dispatch({ type: 'ETH_CONNECT_INIT' });
}

export async function erroConnect(dispatch) {
    dispatch({ type: 'ETH_CONNECT_ERROR' });
}

// export async function uniswapMidPrice(dispatch, ethWeb3Api) {
//     try {
//         const networkId = await ethWeb3Api.eth.net.getId();
//         const VETH = new Token(networkId, networkId === 1 ? process.env.REACT_APP_VETH_CONTRACT_ID : process.env.REACT_APP_VETH_CONTRACT_ID_TEST, 1)
//         const pair = await Fetcher.fetchPairData(WETH[VETH.chainId], VETH)
//         const route = new Route([pair], WETH[VETH.chainId])
//         dispatch({ type: 'VETH_TO_ETH_TRADE_RATE', payload: ethWeb3Api.utils.fromWei(route.midPrice.toSignificant(6)) * 10 })
//         console.log(route.midPrice.toSignificant(6), ethWeb3Api.utils.fromWei(route.midPrice.toSignificant(6), 'ether'), route.midPrice.invert().toSignificant(6))
//     } catch (error) {

//     }

//     // route.midPrice.toSignificant(6);  
//     // route.midPrice.invert().toSignificant(6) // 0.00496756
// }


export async function uniswapExecutionPrice(ethWeb3Api, amount, eth2veth = true) {
    console.log('----request', amount, eth2veth);
    try {
        if (amount <= 0) return
        const networkId = await ethWeb3Api.eth.net.getId();
        const VETH = new Token(networkId, networkId === 1 ? process.env.REACT_APP_VETH_CONTRACT_ID : process.env.REACT_APP_VETH_CONTRACT_ID_TEST, 1)
        const pair = await Fetcher.fetchPairData(WETH[VETH.chainId], VETH)
        const route = new Route([pair], eth2veth ? WETH[VETH.chainId] : VETH)
        const trade = new Trade(route, new TokenAmount(eth2veth ? WETH[VETH.chainId] : VETH, ethWeb3Api.utils.toWei(String(amount))), TradeType.EXACT_INPUT)

        // console.log(trade.priceImpact.toSignificant(6));
        // console.log(trade.executionPrice.toSignificant(6));
        // console.log(trade.outputAmount.toSignificant(6));

        return {
            success: true,
            amount: amount,
            vethToEthTradeRate: eth2veth ? ethWeb3Api.utils.fromWei(trade.executionPrice.toSignificant(6)) * 10 : 0,
            ethToVethTradeRate: eth2veth ? 0 : trade.outputAmount.toSignificant(6),

        }
    } catch (error) {

    }
}

