const Util = {
  sleep: (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  },

  formatError(error) {
    return (error instanceof Error || typeof error === 'string') ? error : JSON.stringify(error)
  },
}

export default Util
