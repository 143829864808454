import IOST from "iost";
import Util from "./Util";
import * as Sentry from "@sentry/browser";

export default class IostHandler {
  constructor(accountObj, iost = null) {
    // init iost sdk
    if (iost) {
      this.iost = iost;
      this.rpc = this.iost.rpc;
    } else {
      this.iost = new IOST.IOST({
        // will use default setting if not set
        gasRatio: 1,
        gasLimit: 4000000,
        delay: 0,
        expiration: 90,
        defaultLimit: "unlimited"
      });

      this.rpc = new IOST.RPC(
        new IOST.HTTPProvider(process.env.REACT_APP_IOST_HOST)
      );

      if (accountObj) {
        this.account = new IOST.Account(accountObj.name);
        const activeKey = new IOST.KeyPair(
          IOST.Bs58.decode(accountObj.activeKey)
        );
        this.account.addKeyPair(activeKey, "active");
        this.iost.setAccount(this.account);
      }

      this.iost.setRPC(this.rpc);
    }
  }

  async getAccount() {
    return this.iost.currentAccount;
  }

  async getBalance(address, tokenSymbol = "iost", useLongestChain = 0) {
    return await this.rpc.blockchain.getBalance(
      address,
      tokenSymbol,
      useLongestChain
    );
  }

  async getIOSTBalance(account) {
    return await this.getBalance(account, "iost", 1);
  }

  async getVOSTBalance(account) {
    return await this.getBalance(account, "vost", 1);
  }

  async getAccountInfo(id, reversible = true) {
    return await this.rpc.blockchain.getAccountInfo(id, reversible);
  }

  async getTable(contractId, key, field = "", by_longest_chain = true) {
    let response = await this.rpc.blockchain.getContractStorage(
      contractId,
      key,
      field,
      by_longest_chain
    );
    return JSON.parse(response.data);
  }

  async getChainInfo() {
    return await this.rpc.blockchain.getChainInfo();
  }

  signAndSendTx(tx, callbacks) {
    let lock = false;
    let txId = null;

    return new Promise(async (resolve, reject) => {
      this.iost
        .signAndSend(tx)
        .on("pending", async args => {
          lock = true;
          txId = args;
          try {
            await callbacks.pending(args);
          } catch (e) {
            Sentry.captureException(e);
            reject({ result: "pending", error: e });
          }
          lock = false;
        })
        .on("success", async args => {
          while (txId && lock) await Util.sleep(50);
          try {
            await callbacks.success(args);
          } catch (e) {
            Sentry.captureException(e);
            reject({ result: "success", error: e });
          }
          resolve({ result: "success", tx });
        })
        .on("failed", async args => {
          while (txId && lock) await Util.sleep(50);
          try {
            Sentry.captureException(args);
            const { message } = args;
            await callbacks.failed(message);
          } catch (e) {
            Sentry.captureException(e);
            reject({ result: "failed", error: e });
          }
          resolve({ result: "failed", tx });
        });
    });
  }

  async callABI(contract, abi, args, callbacks) {
    let tx = this.iost.callABI(contract, abi, args);
    process.env.NODE_ENV === "development" && tx.setChainID(1020);
    tx.setGas(1, 1000000);
    tx.addApprove("vost", 500000000);
    tx.addApprove("iost", 500000000);

    return await this.signAndSendTx(tx, callbacks);
  }

  async toIOST(account, amount, callbacks) {
    const contract = process.env.REACT_APP_VOST_CONTRACT_ID;
    const abi = "toIOST";
    const args = [account, Number(amount).toFixed(8)];

    return await this.callABI(contract, abi, args, callbacks);
  }

  async toIOSTDelay(account, amount, callbacks) {
    const contract = process.env.REACT_APP_VOST_CONTRACT_ID;
    const abi = "toIOSTDelay";
    const args = [account, Number(amount).toFixed(8)];

    return await this.callABI(contract, abi, args, callbacks);
  }

  async toVOST(account, amount, callbacks) {
    const contract = process.env.REACT_APP_VOST_CONTRACT_ID;
    const abi = "toVOST";
    const args = [account, Number(amount).toFixed(8)];

    return await this.callABI(contract, abi, args, callbacks);
  }

  async getCandidateBonus(candidate, by_longest_chain = true) {
    let _provider = this.rpc.getProvider();
    const name = candidate;
    const api = `getCandidateBonus/${name}/${by_longest_chain}`;

    return _provider.send("get", api, null);
  }

  async getVoterBonus(voter, by_longest_chain = true) {
    let _provider = this.rpc.getProvider();
    const name = voter;
    const api = `getVoterBonus/${name}/${by_longest_chain}`;

    return _provider.send("get", api, null);
  }

  async getRewards(account) {
    const contract = process.env.REACT_APP_QUARTERLY_CONTRACT_ID;

    return await this.getTable(contract, 'b_' + account);
  }

  async getIsClaimRewards(account) {
    const contract = process.env.REACT_APP_QUARTERLY_CONTRACT_ID;

    return await this.getTable(contract, 'c_' + account);
  }

  async claimRewards(account, callbacks) {
    const contract = process.env.REACT_APP_QUARTERLY_CONTRACT_ID;
    const abi = "claim";
    const args = [account];

    return await this.callABI(contract, abi, args, callbacks);
  }

  topupVoterBonus(producer, amount, payer, callbacks) {
    const args = [producer, amount, payer];
    const tx = this.callABI(
      "vote_producer.iost",
      "topupVoterBonus",
      args,
      callbacks
    );

    return tx;
  }
}
