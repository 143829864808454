import { rgba } from "polished";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { CoinIcon, color, Flex, radius, shadow, SVG, Text, View } from "./Styles";
export default ({ setPathname }) => {
  const { t, i18n } = useTranslation();
  const [menuVisable, setMenuVisable] = useState(false);

  return (
    <Flex aic style={{ position: 'relative' }}>
      <SVG
        svg={IconDropdown}
        height={1.5}
        mr={0.75}
        r={radius.rounded}
        fill={color.black}
        onMouseEnter={() => setMenuVisable(true)}
        onClick={() => setMenuVisable(true)}
        b={color.border.md}
      />

      {menuVisable && (
        <Menu
          mobile
          aifs
          p={0.05}
          r={radius.lg}
          s={shadow.sm}
          bg={color.white}
          z={1000}
          onMouseLeave={() => setMenuVisable(!menuVisable)}
        >

          <View>
            <Flex column jcsb aic>
              <SVG
                svg={IconDropdown}
                height={1.5}
                mr={0.75}
                r={radius.rounded}
                fill={color.black}
                bg={color.washedGray}
                onClick={() => setMenuVisable(false)}
                b={color.transparent}
              />
              <Flex my={1}>
                <CoinNavLink
                  abbr="iost"
                  name="IOST"
                  setPathname={setPathname}

                />
              </Flex>
              <Flex my={1}>
                <CoinNavLink
                  abbr="eth"
                  name="ETH"
                  setPathname={setPathname}
                />
              </Flex>
            </Flex>
          </View>



        </Menu>
      )}

    </Flex>
  );
};
export const Menu = styled(Flex)`

position: absolute;
top: 0.1em;
left:-3em
`;
const CoinNavLink = props => (
  <StyledCoinNavLink
    key={props.name}
    to={"/" + (props.abbr ? props.abbr : "")}
    exact={props.exact}
    onClick={() => props.setPathname(`/${props.abbr}`)}
    color={(function () {
      let navColor;

      switch (props.abbr) {
        case "iost":
          navColor = color.iost;
          break;
        case "eth":
          navColor = color.eth;
          break;
        case "eos":
          navColor = color.eos;
          break;
        case "atom":
          navColor = color.atom;
          break;
        case "lpt":
          navColor = color.lpt;
          break;
        case "ksm":
          navColor = color.ksm;
          break;
        case "lol":
          navColor = color.lol;
          break;
        case "pcx":
          navColor = color.pcx;
          break;
        case "dot":
          navColor = color.dot;
          break;
        default:
          navColor = color.lightGray;
          break;
      }

      return navColor;
    })()}
  >
    <CoinIcon abbr={props.abbr} />
    {props.abbr && (
      <View ml={0.75} mr={1.5}>
        <Text bold uppercase>
          {props.abbr}
        </Text>
        <Text scale={-2} mt={0.5} color={color.gray}>
          {props.description}
        </Text>
      </View>
    )}
  </StyledCoinNavLink>
);

const StyledCoinNavLink = styled(NavLink)`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 0.1em;
    border-radius: 100px;
    box-shadow: ${shadow.md};
    transition: all 300ms;
    @media (hover: hover) {
      :hover {
        transform: translateY(-2px);
        box-shadow: ${shadow.lg};
      }
    }
    :focus {
      box-shadow: 0 0 0 2px ${p => rgba(p.color, 0.5)};
      outline: none;
    }
    :active,
    &.active {
      transform: translateY(0);
      color: ${p => p.color};
      box-shadow: 0 0 0 2px ${p => p.color},
        0 0 24px 2px ${p => rgba(p.color, 0.15)};
    }
  `;


const IconDropdown = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.6036 18.172C13.7874 18.4637 14.2128 18.4637 14.3966 18.172L18.0854 12.3176C18.282 12.0055 18.0577 11.5991 17.6889 11.5991H10.3113C9.94241 11.5991 9.71813 12.0055 9.91477 12.3176L13.6036 18.172Z" />
  </svg>
);



